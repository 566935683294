import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
} from "gatsby-plugin-react-i18next"

export default function Home() {
  const { t } = useTranslation()
  const { language, languages, originalPath } = useI18next()

  return (
    <Layout>
      <div id="roots">
        <SEO title={t("header.title")} />

        <h2 style={{
          fontSize: "2em",
          lineHeight: "1.25",
          maxWidth: "560px"
        }}><Trans>header.subtitle</Trans></h2>
      </div>
    </Layout>
  )
}
