import PropTypes from "prop-types"
import React from "react"

import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next"

import "./header.css"
import "./typography.css"

const Header = ({ siteTitle }) => {
  const { t } = useTranslation()
  const { language, languages, originalPath } = useI18next()

  return (
    <header>
      <div
        className="titleBar"
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `1rem 0 1.45rem`,
        }}
      >
        <h1 className="title" style={{ margin: 0 }}>
          <Link to="/">{siteTitle}</Link>
        </h1>
        <ul className="languages">
          {languages
            .filter(l => l !== language)
            .map(l => (
              <li key={l}>
                <Link to={originalPath} language={l}>
                  {t(`language.${l}`)}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
